import React from 'react';
import { useForm } from "react-hook-form";
import { endpoint } from '../lib/endpoint'
import { navigate } from 'gatsby';
import ActionButton from './ActionButton';

type FormProps = {
  eventId: string
};

export default ({ eventId }: FormProps) => {
  const { handleSubmit, formState: { isValid, isSubmitting } } = useForm();

  const onSubmit = async (data: any) => {
    console.log('onSubmit', data);
    try {
      const result = await global.fetch(`${endpoint}/registrations/${eventId}`, {
        method: 'PUT',
        credentials: 'include',
        mode: 'cors',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          acceptsContactSharing: true,
        }),
      });
      if (result.status > 201) {
        global.alert(`Oh no, this failed: ${result.statusText} - Please try again, or refresh the page.`);
      } else {
        navigate(`/events/${eventId}`);
      }
    } catch (e) {
      console.error('ERR', e);
      global.alert(`Oh no, an error occurred: ${(e as Error).message || ''} - Please try again, or refresh.`);
    }

  };


  return <>
    <form onSubmit={handleSubmit(onSubmit)}>
      <ActionButton isLoading={false} isSubmitting={isSubmitting} isValid={isValid}>
        {isSubmitting ? 'Registering...' : 'Yes, Register Now'}
      </ActionButton>
    </form>
  </>
}
