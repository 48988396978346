import React from "react";
import { Box, Card, Heading, Text } from "theme-ui";
import { Event } from '@mui/icons-material'
import { Helmet } from "react-helmet";
import AuthenticatedDataView from "../../components/AuthenticatedDataView";
import Layout from "../../components/Layout";
import {endpoint} from "../../lib/endpoint";
import LinkInApp from "../../components/LinkInApp";
import RegistrationConfirmationForm from "../../components/RegistrationConfirmationForm";

type Params = {
  params: {
    id: string
  }
}

type EventMetaData = {
  isLoggedIn: boolean,
  isEventOwner: boolean,
  isRegistered: boolean
};

type EventData = {
  event: {
    id: number,
    title: string,
    description: string,
    guid: string,
  },
  meta: EventMetaData
};

type EventOnCardProps = {
  data: EventData
};

const EventHeading = ({ eventTitle }: { eventTitle: string }) => (
  <Heading>
    <Box sx={{ display: 'inline', pr: 2 }}>
      <Event />
    </Box>
    {eventTitle}
  </Heading>
);

const EventOnCard = ({ data }: EventOnCardProps) => {

  const { event, meta } = data;
  if (!event.title) {
    return <Card><Text>There is no such event. Oops.</Text></Card>;
  }

  if (meta.isRegistered) {
    return (
      <Card>
        <Helmet title={`Registered: ${event.title}`} />
        <EventHeading eventTitle={event.title} />
        <Box>
          <Text>
            You are registered already.
            &nbsp;
            <LinkInApp to={`/events/${event.guid}`}>Go back</LinkInApp> to the event.
          </Text>
        </Box>
      </Card>
    );
  }

  // TODO: Card is almost repeated from pages/events/:id
  return (
    <Card>
      <Helmet title={`Register for: ${event.title}`} />
      <EventHeading eventTitle={event.title} />
      <Box>
        <Text>By registering, you confirm that your contact details will be shared with the organizer.</Text>
      </Box>
      <RegistrationConfirmationForm eventId={event.guid} />
      <Box>
        <Text pl={2}>
          <LinkInApp to={`/events/${event.guid}`}>Go back</LinkInApp> to the event.
        </Text>
      </Box>
    </Card>
  );
}

export default ({ params }: Params) => {
  console.log('render event, params', params);
  const { id } = params;
  return (
    <Layout>
      <AuthenticatedDataView fetchUrl={`${endpoint}/events/${id}?forceLogin=true`}
        render={data => <>
          <EventOnCard data={data as EventData} />
        </>}
      />
    </Layout>
  )
}
